import React from 'react'

import classnames from 'classnames'

import * as styles from './styles.module.scss'

const PrivacyPolicy = () => (
  <div
    className={classnames(
      styles.privacyPolicyWrapper,
      'col-centered',
      '--narrow'
    )}
  >
    <h2 className={styles.h2}>General Information</h2>
    <h3 className={styles.h3}>Service description and provider</h3>
    <p>
      Shadow Esports GmbH, domiciled in Axel-Springer-Str. 54b, 10117 Berlin
      ("Shadow Esports", "we" or "our"), takes the protection of your personal
      data very seriously and therefor makes available to you the following
      information (the "Privacy Policy").
    </p>
    <p>
      Shadow Esports is a developer and publisher of apps, mobile apps and web
      based app services (each the "App" or together the "Apps") that are made
      available either via App Stores, including the Apple Appstore and the
      Google Play Store, or directly by Shadow Esports on or via its websites.
      In addition to the use of the Apps, we also provide related services (e.g.
      websites, support, information requests and others) (collectively with the
      App, the "Service").
    </p>
    <p>
      Whenever you use our Service, we will be processing personal data, as
      described in the Processing Procedure section of this Privacy Policy. By
      processing the General Data Protection Regulation ("GDPR") references a
      very broad spectrum of operations executed on personal data - basically,
      whenever we or others get in contact with your personal data, we process
      it, according to the GDPR definition. Personal data is information that we
      or others can use to identify a person, i.e. information that can be
      linked to a particular individual.
    </p>
    <p>
      As a company based in the European Union, we treat all personal data in
      compliance with GDPR, no matter where you are from or where you are
      currently located.
    </p>
    <h3 className={styles.h3}>Scope of the privacy policy</h3>
    <p>
      The following Privacy Policy will provide you with information about the
      type of personal data that is collected and how it is processed and used
      during your use of the Service. Unless otherwise provided in this Privacy
      Policy, this Privacy Policy exclusively regulates how Shadow Esports,
      including the companies that help us provide the Service (the "Vendors"),
      processes your personal data. In case you make use of services provided by
      third parties other than the Vendors, the privacy policies of these third
      parties apply exclusively.
    </p>
    <h3 className={styles.h3}>Contact details of the provider</h3>
    <p>Contact Detail of the controller according to Art. 4 GDPR:</p>
    <p>
      Shadow Esports GmbH, Axel-Springer-Str. 54b, 10117 Berlin,
      <br />
      Managing Director: Benjamin Riewe
      <br />
      email:{' '}
      <a
        className='inline'
        href='mailto:privacy@shadow.gg'
        target='_blank'
        rel='noopener noreferrer'
      >
        privacy@shadow.gg
      </a>
      <br />
      District Court Berlin Charlottenburg: HRB 210718 B.
    </p>
    <p>
      Unless otherwise provided in this Privacy Policy and in case you want to
      exercise any right pursuant to this privacy policy or applicable data
      protection laws and regulations or if you have any questions concerning
      privacy policy issues, please contact{' '}
      <a
        className='inline'
        href='mailto:contact@shadow.gg'
        target='_blank'
        rel='noopener noreferrer'
      >
        contact@shadow.gg
      </a>
      . At this address, you can inquire about privacy issues and issue a
      request to review, change or delete your personal data stored by Shadow
      Esports, as described below. Alternatively, you can also contact Shadow
      Esports using the above postal address or by phone. This will cause you no
      other costs than the transmission costs stated in the base rates (i.e. the
      costs of your Internet, e-mail, mobile phone or telephone connection).
    </p>
    <p>
      You can reach our data protection officer at the following contact
      details:
    </p>
    <p>
      ClixOn GmbH, Oranienburger Straße 70, 10117 Berlin
      <br />
      Email:{' '}
      <a
        className='inline'
        href='mailto:contact@shadow.gg'
        target='_blank'
        rel='noopener noreferrer'
      >
        contact@shadow.gg
      </a>
    </p>
    <h3 className={styles.h3}>Rights</h3>
    <p>
      In accordance with the requirements pursuant to the GDPR, you may exercise
      the following rights:
    </p>
    <h5 className={styles.h5}>Right to be informed (Art. 12 GDPR)</h5>
    <p>
      You have the right to be informed about how Shadow Esportss processes
      personal data. To this end this privacy policy will provide you with
      descriptions of the purpose (why we use your data), the retention policy
      (how long we process data) and who we share personal data with (our
      processors/Vendors).
    </p>
    <h5 className={styles.h5}>Right of access (Art. 15 GDPR)</h5>
    <p>
      You have the right to know what personal data about you we use and
      process. Please have a look at the Processing Procedure sections below for
      a comprehensive overview. If you want to know more, get in contact with us
      using the contact details provided above.
    </p>
    <h5 className={styles.h5}>Right to rectification (Art. 16 GDPR)</h5>
    <p>
      You have the right to request, that we correct inaccurate data we process
      about you or that we add data, where it is incomplete. Some of the
      information we process is being provided by you during the account
      creation or during the use of the App and may be modified through the App
      itself. If you feel that other personal data may be incorrect, simply get
      in touch with us, as described above.
    </p>
    <h5 className={styles.h5}>Right to erasure (Art. 17 GDPR)</h5>
    <p>
      In some instances, we may ask for your consent before processing your
      data. If you change your mind later, you can simply change the respective
      setting in the Service and we will no longer process any consent based
      data. Please note that we automatically erase your data, if it no longer
      necessary for the purpose we collected or processed it for. We will also
      ensure that data is erased from Vendor systems. If, for example, you have
      contacted our customer support and requested help, we will erase all
      personal data once your request has been solved, since your personal data
      is no longer needed for the purpose of providing you with customer
      support. If beyond that you believe that you have a right to have your
      personal data deleted, please contact us as described above.
    </p>
    <h5 className={styles.h5}>Right to restrict processing (Art. 18 GDPR)</h5>
    <p>
      When you exercise your right to rectification, to object or to erasure you
      may ask Shadow Esports to restrict the processing of personal information
      to the extent necessary to review your request. If you continue using our
      Service(s) during such a request, we will also process your personal data
      to provide you with the Service. If beyond that you believe that you have
      the right to have your personal data use restricted, please contact us as
      described above.
    </p>
    <h5 className={styles.h5}>Right to data portability (Art 20 GDPR)</h5>
    <p>
      You may ask us to export for you the personal data that you provided us
      with during the Service. This does however not include any data we have
      created based on the personal data you provided us with. You may also ask
      us to have your personal data transmitted from us to another controller.
      Please note that we will consider the technical feasibility on a request
      by request basis. If you choose to exercise this right, simply get in
      contact with us using the contact information provided above.
    </p>
    <h5 className={styles.h5}>Right to object (Art. 21 GDPR)</h5>
    <p>
      To the extent that Shadow Esports' data processing is based on legitimate
      interest, you can object the processing of data we engage into at any
      point. Please check the Processing Procedures below to see if the
      processing you are objecting to is based on legitimate interest. You can
      find this information under the Legal Basis subsections. If you choose to
      exercise this right, simply get in contact with us using the contact
      information provided above.
    </p>
    <h5 className={styles.h5}>
      Rights in relation to automated decision making and profiling (Art. 22
      GDPR)
    </h5>
    <p>
      We do not engage in automated decision making or profiling according to
      the definitions provided under GDPR.
    </p>
    <h5 className={styles.h5}>The right to lodge a complaint (Art. 77 GDPR)</h5>
    <p>
      You may get in contact with the supervisory authority if you believe that
      the data concerning you is being processed by Shadow Esports in breach of
      data protection provisions. The Berlin Commissioner for Data Protection
      and Freedom of Information is responsible for Shadow Esports and may be
      contacted as provided{' '}
      <a
        className='inline'
        href='https://www.datenschutz-berlin.de/kontakt.html'
        target='_blank'
        rel='noopener noreferrer'
      >
        here.
      </a>
    </p>
    <p>
      In each case mentioned in this Rights section please always ensure to
      provide us with all the all necessary information to process your request.
      <br />
      Once we receive all necessary information, we will usually get back to you
      no later than a month after you completed your request.
      <br />
      Please note, that we may require that you provide means of authentication,
      if we are not sure about your identity.
    </p>
    <h2 className={styles.h2}>Processing Procedures</h2>
    <h3 className={styles.h3}>Access of the website</h3>
    <p>
      Whenever you access our website (the <strong>"Website"</strong>), without
      using any additional features or services as described below, we process
      data that your browser and the servers on which the website is hosted,
      automatically exchange.
    </p>
    <h5 className={styles.h5}>Your Data</h5>
    <div className={styles.div}>
      In order for the content of the Website being displayed on your device, we
      process the following data, including as applicable personal data:
      <ul className={styles.ul}>
        <li>Date and time of access;</li>
        <li>Amount of data sent;</li>
        <li>Browser used;</li>
        <li>Operating system used;</li>
        <li>IP address used. </li>
      </ul>
    </div>
    <h5 className={styles.h5}>Data transfer</h5>
    <h6 className={styles.h6}>Vendors</h6>
    <p>
      Vendors provide applications and services, in this case host services and
      content delivery service for the Website
    </p>
    <p>
      Our Vendor for the hosting and delivery of the website is{' '}
      <strong>Amazon Web Service, Inc.</strong>.
      <br />
      We have contractually agreed with this Vendor that it may only use your
      data as instructed by Shadow Esports and for our services. Any other use -
      including contact by the this Vendor - is only permitted with your prior
      consent.
    </p>
    <h6 className={styles.h6}>Payment Data</h6>
    <p>
      Customers must provide their name, payment method and related payment
      details. Additionally, we may receive your IP address, transaction
      identifiers and metadata as part of completing your purchase. We use Zoho
      Subscriptions and Stripe to process payments and never process or store
      your credit card or bank information on our servers.
    </p>
    <h6 className={styles.h6}>Authorised public authorities</h6>
    <p>
      Law enforcement authorities or public authorities (e.g. tax authorities,
      social insurance), supervisory authorities in the event of requests for or
      obligations to provide information in the event of legal action,
      preliminary proceedings and to our own tax advisors, auditors and lawyers
      in order to exercise our rights and our legal obligations.
    </p>
    <h6 className={styles.h6}>Change in ownership</h6>
    <p>
      In the event of a takeover or merger with another company, we will pass on
      your data to that company. In such a case, we will ensure that the
      applicable data protection regulations are complied with and that the data
      will only be processed to the same extent as described in this policy.
    </p>
    <h5 className={styles.h5}>Legal basis</h5>
    <p>
      Your data related to displaying the Website will be processed pursuant to
      Art. 6 para. 1 lit. b GDPR, which allows for the processing of personal
      data for the purpose of fulfilling a contract.
    </p>
    <h5 className={styles.h5}>Data retention</h5>
    <p>
      Any processing of this data will cease as soon as you stop interacting
      with the website. Data is automatically deleted no later than 24 hours
      after your visit.
    </p>
    <h3 className={styles.h3}>Server Log Files</h3>
    <p>
      Each time you access the Website, we automatically collect a series of
      technical data and store them in our systems (the "Server Log Files"),
      some of which is considered personal data. We use Server Log Files to
      monitor and secure the services we offer to you and in order to improve
      and better our services, including error tracking, usage evaluation, anti
      intrusion and fraud protection.
    </p>
    <h5 className={styles.h5}>Your Data</h5>
    <div className={styles.div}>
      The data processed in Server Log Files includes:
      <ul className={styles.ul}>
        <li>IP Address,</li>
        <li>operating system version,</li>
        <li>
          the configuration of your device and browser when accessing the
          Website,
        </li>
        <li>the time and date of your use of the Website.</li>
      </ul>
    </div>
    <p>
      This data is not combined with other personal data that you provide Shadow
      Esports during the use of the Website. We collect Server Log files for the
      purpose of administering the Website and being able to recognize and
      prevent unauthorized access.
    </p>
    <h5 className={styles.h5}>Data transfer</h5>
    <h6 className={styles.h6}>Vendors</h6>
    <p>
      Vendors provide applications and services, e.g. host computers, backup
      services, database services, error tracking, and technical analysis, anti
      intrusion and fraud protection.
    </p>
    <p>
      These vendors are <strong>Amazon Web Service, Inc.</strong>.
      <br />
      We have contractually agreed with this Vendor that they may only use your
      data as instructed by Shadow Esports and for our services. Any other use -
      including contact by this Vendor - is only permitted with your prior
      consent.
    </p>
    <h6 className={styles.h6}>Authorised public authorities</h6>
    <p>
      Law enforcement authorities or public authorities (e.g. tax authorities,
      social insurance), supervisory authorities in the event of requests for or
      obligations to provide information in the event of legal action
      preliminary proceedings and to our own tax advisors, auditors and lawyers
      in order to exercise our rights and our legal obligations.
    </p>
    <h6 className={styles.h6}>Change in ownership</h6>
    <p>
      In the event of a takeover or merger with another company, we will pass on
      your data to that company. In such a case, we will ensure that the
      applicable data protection regulations are complied with and that the data
      will only be processed to the same extent as described in this policy.
    </p>
    <h5 className={styles.h5}>Legal basis</h5>
    <p>
      Server Log Files are processed pursuant to Art. 6 para. 1 lit. f GDPR,
      which allows the processing of personal data based on a "legitimate
      interest" of the controller, provided that your fundamental rights,
      freedoms or interests do not prevail. Shadow Esports' legitimate interest
      in this instance is to make administration easier and to detect and to
      track unauthorised access attempts, errors and attacks on the services.
      You may object to this data processing at any time if you think there may
      be particular reasons regarding your particular situation which result in
      your interest prevailing against this data processing.
    </p>
    <h5 className={styles.h5}>Data retention</h5>
    <p>
      Unless otherwise provided herein, personal data contained in Server Log
      Files is automatically deleted and generally not processed longer than 90
      days. We reserve the right to store the server log files for longer if
      there is evidence suggesting that an incident (such as an attempt at
      hacking, a database error or a so-called DDOS attack) has taken place. In
      this case the Server Log Files in question will be deleted, once the
      investigation regarding the incident has been closed.
    </p>
    <h3 className={styles.h3}>Contact</h3>
    <p>
      We offer you the possibility to get in contact via email, telephone or
      through other services. You may get in contact with us in order for us to
      fulfill applicable legal obligations such as assisting you with data
      protection related requests under GDPR or for other types of inquiries or
      requests or if you have questions regarding our Service.
    </p>
    <h5 className={styles.h5}>Your Data</h5>
    <div className={styles.div}>
      When you get in contact with us, certain personal data is processed. You
      are able to decide which personal data you provide us with during your
      request, but in general we need your contact information, to respond to
      your request. We therefore, process the following information:
      <ul className={styles.ul}>
        <li>phone number;</li>
        <li>email address;</li>
        <li>other contact information, as provided by you.</li>
      </ul>
    </div>
    <p>
      This data is stored and processed exclusively for the purpose of
      responding to your request, for establishing contact and the associated
      technical administration.
    </p>
    <h5 className={styles.h5}>Data transfer</h5>
    <h6 className={styles.h6}>Vendors</h6>
    <p>
      Vendors provide applications and services, in this case host services and
      email provider services.
    </p>
    <p>
      Our Vendor for the hosting and processing of all our emails is the{' '}
      <strong>Google LLC</strong> through their Google Apps for Business'
      service respectively.
      <br />
      We have contractually agreed with these Vendors that they may only use
      your data as instructed by Shadow Esports and for our services. Any other
      use - including contact by these Vendors - is only permitted with your
      prior consent.
    </p>
    <h6 className={styles.h6}>Authorised public authorities</h6>
    <p>
      Law enforcement authorities or public authorities (e.g. tax authorities,
      social insurance), supervisory authorities in the event of requests for or
      obligations to provide information in the event of legal action
      preliminary proceedings and to our own tax advisors, auditors and lawyers
      in order to exercise our rights and our legal obligations.
    </p>
    <h6 className={styles.h6}>Change in ownership</h6>
    <p>
      In the event of a takeover or merger with another company, we will pass on
      your data to that company. In such a case, we will ensure that the
      applicable data protection regulations are complied with and that the data
      will only be processed to the same extent as described in this policy.
    </p>
    <h5 className={styles.h5}>Legal basis</h5>
    <p>
      Your contact data will be processed pursuant to Art. 6 para. 1 lit. b
      GDPR, which allows for the processing of personal data for the purpose of
      fulfilling a contract or Art. 6 para. 1 lit. c GDPR, which allows for the
      processing of personal data for the purpose of compliance with a legal
      obligation to which the controller is subject, depending from the scope
      and reason of your request.
    </p>
    <h5 className={styles.h5}>Data retention</h5>
    <p>
      This data will be deleted after your request has been processed: This is
      the case if it is clear from the circumstances that the matter has been
      finally clarified and no legal storage obligations to the contrary exist.
    </p>
    <h3 className={styles.h3}>Careers and Job application</h3>
    <p>
      We provide you with the opportunity to review our current job openings and
      to directly apply for them through our Website.
    </p>
    <h5 className={styles.h5}>Your Data</h5>
    <p>
      We provide you with different methods to apply to open positions at Shadow
      Esports and our affiliate companies:
      <br />
      Firstly you can apply through our AngelList site, available{' '}
      <a
        className='inline'
        href='https://angel.co/dojo-madness/jobs'
        target='_blank'
        rel='noopener noreferrer'
      >
        here.
      </a>
      AngelList provides a platform for entrepreneurs and companies to get in
      contact with prospective investors and employees, where you must create an
      account in order to apply. AngelList acts as an independent data
      controller with regard as all the information that you provide them with.
      Additional information can be found in their privacy policy{' '}
      <a
        className='inline'
        href='https://angel.co/privacy'
        target='_blank'
        rel='noopener noreferrer'
      >
        here.
      </a>
    </p>
    <p>
      You can also apply through Indeed, another portal through which companies
      can offer and employees may apply to open positions, available{' '}
      <a
        className='inline'
        href='https://de.indeed.com/'
        target='_blank'
        rel='noopener noreferrer'
      >
        here.
      </a>
      Indeed acts as an independent data controller, with regard to the
      information that you provide them with. Additional information can be
      found in their privacy policy{' '}
      <a
        className='inline'
        href='https://de.indeed.com/legal?hl=de'
        target='_blank'
        rel='noopener noreferrer'
      >
        here.
      </a>
    </p>
    <div className={styles.div}>
      Lastly you may also apply through email. In all cases we will process all
      the information, including personal data, that you supply us with, which
      typically consists of:
      <ul className={styles.ul}>
        <li>
          Contact information, such as your name, your address, your email
          address and your phone number;
        </li>
        <li>
          Historical data, such as where you have previously worked at and your
          life history relevant to the application;
        </li>
        <li>Medical and health data, such as inabilities;</li>
        <li>
          Religious believe to the extent relevant for tax reasons under
          applicable law.
        </li>
      </ul>
    </div>
    <h5 className={styles.h5}>Data transfer</h5>
    <h6 className={styles.h6}>Vendor</h6>
    <p>
      Vendors provide applications and services, in this case host services and
      email provider services.
    </p>
    <p>
      Our Vendor for the hosting and processing of all our email communication,
      including for the purpose of application, is the{' '}
      <strong>Google LLC</strong> through their Google Apps for Business'
      service.
      <br />
      We have contractually agreed with this Vendor that it may only use your
      data as instructed by Shadow Esports and for our services. Any other use -
      including contact by this Vendor - is only permitted with your prior
      consent.
    </p>
    <h6 className={styles.h6}>Authorised public authorities</h6>
    <p>
      Law enforcement authorities or public authorities (e.g. tax authorities,
      social insurance), supervisory authorities in the event of requests for or
      obligations to provide information in the event of legal action
      preliminary proceedings and to our own tax advisors, auditors and lawyers
      in order to exercise our rights and our legal obligations.
    </p>
    <h6 className={styles.h6}>Change in ownership</h6>
    <p>
      In the event of a takeover or merger with another company, we will pass on
      your data to that company. In such a case, we will ensure that the
      applicable data protection regulations are complied with and that the data
      will only be processed to the same extent as described in this policy.
    </p>
    <h5 className={styles.h5}>Legal basis</h5>
    <p>
      Your application data will be processed pursuant to Art. 6 para. 1 lit. b
      GDPR, which allows for the processing of personal data for the purpose of
      fulfilling a contract.
    </p>
    <h5 className={styles.h5}>Data retention</h5>
    <p>
      All information that you provide us for the purpose of applying to a job,
      will be deleted 90 days after we have filled the relevant position.
    </p>
    <h3 className={styles.h3}>Blog</h3>
    <p>
      We also provide a so called blog on the Website (the "Blog"), where we
      publish articles relevant to our interests, give you the opportunity to
      interact with us and leave comments to such published articles.
    </p>
    <h5 className={styles.h5}>Your Data</h5>
    <p>
      The Blog functionalities are provided by the{' '}
      <strong>A Medium Corporation</strong>, through their Medium Service. The
      Medium Service is a service providing social journalism, having a hybrid
      collection of amateur and professional people and publications, or
      exclusive blogs or publishers on Medium, and is regularly regarded as a
      blog host. Additional information can be found in their privacy policy
      available{' '}
      <a
        className='inline'
        href='https://medium.com/policy/medium-privacy-policy-f03bf92035c9'
        target='_blank'
        rel='noopener noreferrer'
      >
        here.
      </a>
    </p>
    <div className={styles.div}>
      When you access the Blog functions of the Website, we will analyse how you
      interact with the Blog. To this end
      <ul className={styles.ul}>
        <li>blog cookies,</li>
        <li>analytic cookies and</li>
        <li>other personal data, as provided by you.</li>
      </ul>
    </div>
    <h5 className={styles.h5}>Data transfer</h5>
    <h6 className={styles.h6}>Vendor</h6>
    <p>
      Vendors provide applications and services, in this case host services
      provider services and content delivery services.
    </p>
    <p>
      Our Vendors for the hosting and processing of all Blog features, including
      for the purpose of analytics and content delivery are the A Medium
      Corporation.
      <br />
      Additional information can be found in their privacy policy available{' '}
      <a
        className='inline'
        href='https://medium.com/policy/medium-privacy-policy-f03bf92035c9'
        target='_blank'
        rel='noopener noreferrer'
      >
        here.
      </a>
    </p>
    <h6 className={styles.h6}>Authorised public authorities</h6>
    <p>
      Law enforcement authorities or public authorities (e.g. tax authorities,
      social insurance), supervisory authorities in the event of requests for or
      obligations to provide information in the event of legal action
      preliminary proceedings and to our own tax advisors, auditors and lawyers
      in order to exercise our rights and our legal obligations.
    </p>
    <h6 className={styles.h6}>Change in ownership</h6>
    <p>
      In the event of a takeover or merger with another company, we will pass on
      your data to that company. In such a case, we will ensure that the
      applicable data protection regulations are complied with and that the data
      will only be processed to the same extent as described in this policy.
    </p>
    <h5 className={styles.h5}>Legal basis</h5>
    <p>
      The data processing for the purpose of providing Blog functionalities is
      based on your consent, which you gave during the first visit to the Blog
      section of the Website. Pursuant to Art. 6 para. 1 lit. a GDPR, data
      processing is permitted if you have given your consent for data processing
      for one or more specific purposes. Within the scope of the opt in you have
      given a declaration of consent with which you have agreed to evaluation of
      your use of the blog functionalities. A revocation is possible at any time
      free of charge through the Website's privacy settings.
    </p>
    <h5 className={styles.h5}>Data retention</h5>
    <p>
      You can opt out from Blog functionality cookies at any point. To opt out
      of these services on the Website on your device, simply change the
      respective setting in your browser's privacy settings by enabling the do
      not track functions and delete the cookie.
    </p>
    <h3 className={styles.h3}>Analytics Cookies</h3>
    <p>
      We use reports provided by analytics providers to help us understand
      Website traffic and Website usage. Analytics reports also allow us to
      connect a download of the App to a launch and login to the App on your
      device.
    </p>
    <h5 className={styles.h5}>Your Data</h5>
    <p>
      When you access our Website, we will analyse how our Website is used.
      <br />
      To this end analytics cookies are set on your device. These cookie enables
      us to track your use and interaction of the Website and functionalities
      offered thereon.
      <br />
      We have enabled the anonymisation feature in Google Analytics, which
      anonymizes the IP Address of the device accessing the Service.
    </p>
    <h5 className={styles.h5}>Data transfer</h5>
    <h6 className={styles.h6}>Vendors</h6>
    <p>
      Vendors provide applications and services, in this case host and analytics
      services.
    </p>
    <p>
      These Vendors are the Google, LLC through their Google Analytics service.
      <br />
      We have contractually agreed with these Vendors that they may only use
      your data as instructed by Shadow Esports and for our services. Any other
      use - including contact by these Vendors - is only permitted with your
      prior consent.
    </p>
    <h6 className={styles.h6}>Authorised public authorities</h6>
    <p>
      Law enforcement authorities or public authorities (e.g. tax authorities,
      social insurance), supervisory authorities in the event of requests for or
      obligations to provide information in the event of legal action
      preliminary proceedings and to our own tax advisors, auditors and lawyers
      in order to exercise our rights and our legal obligations.
    </p>
    <h6 className={styles.h6}>Change in ownership</h6>
    <p>
      In the event of a takeover or merger with another company, we will pass on
      your data to that company. In such a case, we will ensure that the
      applicable data protection regulations are complied with and that the data
      will only be processed to the same extent as described in this policy.
    </p>
    <h5 className={styles.h5}>Legal basis</h5>
    <p>
      Analytic data is processed pursuant to Art. 6 para. 1 lit. f GDPR, which
      allows the processing of personal data based on a "legitimate interest" of
      the controller, provided that your fundamental rights, freedoms or
      interests do not prevail. Shadow Esports' legitimate interest in this
      instance is to have an insight on how our website is used, where traffic
      is coming from and where it leaves the Service to. You may object to this
      data processing at any time if you think there may be particular reasons
      regarding your particular situation which result in your interest
      prevailing against this data processing.
    </p>
    <h5 className={styles.h5}>Data retention</h5>
    <p>
      Notwithstanding anything to the contrary, you can opt out from analytic
      services at any point. To opt out of Google Analytics services on the
      Website on your device, please change your setting for all websites by
      following this{' '}
      <a
        className='inline'
        href='https://tools.google.com/dlpage/gaoptout?hl=en'
        target='_blank'
        rel='noopener noreferrer'
      >
        link.
      </a>
    </p>
    <p>The data retention for analytic services is 14 months.</p>
  </div>
)

export default PrivacyPolicy
